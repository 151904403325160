import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "./layout"
import SEO from "./seo"
import Img from "gatsby-image"
// import Gallery from "./gallery"
// import Skus from './skus'
// import Add from './add'


export default (props) => {

  const posts = useStaticQuery(graphql`
    query Articles {
      allContentfulArticle {
        edges {
          node {
            updatedAt
            createdAt
            content {
              childMarkdownRemark {
                html
                timeToRead
              }
            }
            cover {
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1280) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            intro
            title
            id
          }
        }
      }
    }
  `)
  const currentArticle = posts.allContentfulArticle.edges.filter(({ node: a }) => { return a.id === props.pageContext.article.id} )[0].node
  currentArticle['type'] = 'article'
    return (
      <Layout>
        <SEO  schemaData={currentArticle} title={currentArticle.title} description={currentArticle.intro} />
        <main className="max-w-2xl mx-auto py-16 px-4">
          <h1 className="text-4xl font-bold mb-2 leading-tight">{ currentArticle.title }</h1>
          <p className="italic mb-8">{ currentArticle.intro }</p>
          <Img className="rounded max-w-sm mx-auto" fluid={currentArticle.cover.localFile.childImageSharp.fluid} />
          <div className="flex bg-white mx-auto container pt-10">
            <div className="article" dangerouslySetInnerHTML={{ __html: currentArticle.content.childMarkdownRemark.html }} />
          </div>
        </main>
      </Layout>
    )
}

